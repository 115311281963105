// extracted by mini-css-extract-plugin
export const main_header = "Header-module--main_header--663Zy";
export const search_bar = "Header-module--search_bar--2s5Hg";
export const close_search_bar = "Header-module--close_search_bar--35bMO";
export const main_header_wrapper = "Header-module--main_header_wrapper--3e8uz";
export const main_nav__mobile = "Header-module--main_nav__mobile--1HcmO";
export const show = "Header-module--show--299Lh";
export const main_nav__mobile_button = "Header-module--main_nav__mobile_button--3lTGH";
export const brand = "Header-module--brand--2lzjd";
export const brand__site_title = "Header-module--brand__site_title--3LSE3";
export const brand__site_logo = "Header-module--brand__site_logo--29qlE";
export const main_header__search_mobile = "Header-module--main_header__search_mobile--1Z_F3";
export const main_header__search_mobile_icon = "Header-module--main_header__search_mobile_icon--1MCbe";
export const main_header__search = "Header-module--main_header__search--MSHZ8";
export const main_nav = "Header-module--main_nav--2i5eJ";
export const active = "Header-module--active--2TfoN";