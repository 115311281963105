import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Model
import { ISite } from "models/Seo"
// Styles
import * as styles from "./FollowUsModal.module.css"

const FollowUsModal: React.FC = () => {
  const [dismissSocial, setDismissSocial] = React.useState<boolean>(true)
  const [showCard, setShowCard] = React.useState<boolean>(false)
  const { site } = useStaticQuery(query) as ISite

  React.useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined
    if (localStorage.getItem("dismiss_social")) {
      setDismissSocial(true)
    } else {
      setDismissSocial(false)
      timeoutId = setTimeout(() => {
        setShowCard(true)
      }, 60000)
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, [])

  const closeSocialCardHandler = () => {
    if (typeof window !== "undefined") {
      localStorage.setItem("dismiss_social", "true")
      setDismissSocial(true)
    }
  }

  if (dismissSocial) return <></>

  if (showCard && !dismissSocial)
    return (
      <section className={styles.follow_us_card}>
        <div className={styles.follow_us_card__header}>
          <span className={styles.follow_us_card__title}>¿Todavía no nos sigues?</span>
          <span onClick={closeSocialCardHandler} className={styles.follow_us_card__close}>
            <FontAwesomeIcon icon={["fas", "times"]} />
          </span>
        </div>
        <div className={styles.follow_us_card__container}>
          <strong>
            Descubrir música nueva, apoyar a artistas al no consumir contenido ilegal y ayudarnos a nosotros
            visibilizando nuestro trabajo.
          </strong>
          <br />
          Todas esas son las consecuencias de seguirnos. <br />
          <strong>¡Qué más se puede pedir!</strong>
          <ul className={styles.follow_us_card__list}>
            <li>
              <a
                className={styles.follow_us_card__list_item}
                data-social="twitter"
                rel="noopener noreferrer"
                target="_blank"
                href={`https://www.twitter.com/${site.siteMetadata.twitter}`}
              >
                <FontAwesomeIcon icon={["fab", "twitter"]} />
                <span>Twitter</span>
              </a>
            </li>
            <li>
              <a
                className={styles.follow_us_card__list_item}
                data-social="facebook"
                rel="noopener noreferrer"
                target="_blank"
                href={`https://fb.me/${site.siteMetadata.facebook}`}
              >
                <FontAwesomeIcon icon={["fab", "facebook"]} />
                <span>Facebook</span>
              </a>
            </li>
            <li>
              <a
                className={styles.follow_us_card__list_item}
                data-social="instagram"
                rel="noopener noreferrer"
                target="_blank"
                href={`https://www.instagram.com//${site.siteMetadata.instagram}`}
              >
                <FontAwesomeIcon icon={["fab", "instagram"]} />
                <span>Instagram</span>
              </a>
            </li>
            <li>
              <a
                className={styles.follow_us_card__list_item}
                data-social="youtube"
                rel="noopener noreferrer"
                target="_blank"
                href={`${site.siteMetadata.youtube}`}
              >
                <FontAwesomeIcon icon={["fab", "youtube"]} />
                <span>Youtube</span>
              </a>
            </li>
          </ul>
          ¡Estamos en estas redes sociales!
          <span onClick={closeSocialCardHandler} className={styles.follow_us_card__button}>
            Ya sigo a {site.siteMetadata.site_name}
          </span>
        </div>
      </section>
    )

  return <></>
}

export default FollowUsModal

const query = graphql`
  query {
    site {
      siteMetadata {
        site_name
        twitter
        facebook
        instagram
        youtube
      }
    }
  }
`
