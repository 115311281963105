import * as React from "react"
import { Link } from "gatsby"

import * as styles from "./Menu.module.css"

const Menu: React.FC = () => {
  return (
    <>
      <Link activeClassName={styles.active} to="/">
        Inicio
      </Link>
      <Link partiallyActive activeClassName={styles.active} to="/songs">
        Canciones
      </Link>
      <Link partiallyActive activeClassName={styles.active} to="/artists">
        Artistas
      </Link>
      <Link partiallyActive activeClassName={styles.active} to="/articles">
        Artículos
      </Link>
    </>
  )
}

export default Menu
