import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";

// Models
import { ISite, IOptionalMetaProps } from "models/Seo";

type SEOProps = {
  seo: IOptionalMetaProps;
};

const SEO: React.FC<SEOProps> = ({ seo }) => {
  const { site } = useStaticQuery(query) as ISite;
  const path = typeof window !== "undefined" ? window.location.pathname : "";
  const expression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

  let imagePath;
  if (seo?.image) {
    if (seo.image.match(expression)) imagePath = seo.image;
    else imagePath = site.siteMetadata.siteUrl + seo.image;
  } else {
    imagePath = site.siteMetadata.siteUrl + site.siteMetadata.image;
  }

  const metaTags = [
    {
      name: `og:title`,
      content: `${seo?.title} | ${site.siteMetadata.site_name}`,
    },
    {
      name: `og:site_name`,
      content: site.siteMetadata.site_name,
    },
    {
      name: `og:url`,
      content: `${site.siteMetadata.siteUrl}${path}`,
    },
    {
      name: `description`,
      content: seo.description || site.siteMetadata.description,
    },
    {
      name: `og:description`,
      content: seo.description || site.siteMetadata.description,
    },
    {
      name: `og:image`,
      content: imagePath,
    },
    {
      name: "author",
      content: seo.author?.name || site.siteMetadata.site_name,
    },
    {
      name: "keywords",
      content: site.siteMetadata.keywords,
    },
    /*
     * TWITTER META
     */
    {
      name: `twitter:card`,
      content: `summary_large_image`,
    },
    {
      name: `twitter:site`,
      content: `@${site.siteMetadata.twitter}`,
    },
    {
      name: `twitter:title`,
      content: `${seo.title} | ${site.siteMetadata.site_name}`,
    },
    {
      name: `twitter:description`,
      content: seo.description || site.siteMetadata.description,
    },
    {
      name: `twitter:creator`,
      content: `@${seo.author?.twitter || site.siteMetadata.twitter}`,
    },
    {
      name: `twitter:image`,
      content: imagePath,
    },
  ];

  // if (seo.hide) metaTags.concat([{ name: "robots", content: "noindex" }]);

  return (
    <Helmet
      htmlAttributes={{
        lang: "es",
      }}
      title={seo.title}
      titleTemplate={`%s | ${site.siteMetadata.site_name} - Música japonesa traducida al español`}
      meta={metaTags}
    >
      {seo.hide && <meta name="robots" content="noindex" />}
    </Helmet>
  );
};

export default SEO;

const query = graphql`
  query {
    site {
      siteMetadata {
        site_name
        description
        siteUrl
        twitter
        keywords
        image
      }
    }
  }
`;
