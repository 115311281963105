import * as React from "react";
import { config } from "@fortawesome/fontawesome-svg-core";
import "fontawesome";
config.autoAddCss = false;

// Provider
import { MDXProvider } from "@mdx-js/react";
import SongLyrics from "components/Sections/Song/SongLyrics";
import Lyric from "components/UIElements/Lyric";
import ContactForm from "components/UIElements/ContactForm";
import ImageViewer from "components/UIElements/ImageViewer";
import Center from "components/UIElements/Align/Center";
import Right from "components/UIElements/Align/Right";
// Components
import SEO from "./Seo";
import Footer from "./Footer";
import Header from "./Header";
import FollowUsModal from "components/UIElements/FollowUsModal";
// Models
import { IOptionalMetaProps } from "models/Seo";
// Styles
import * as styles from "./index.module.css";

type LayoutPros = {
  seo: IOptionalMetaProps;
};

const Layout: React.FC<LayoutPros> = ({ children, seo }) => {
  const shaderComponents = {
    SongLyrics,
    Lyric,
    ContactForm,
    ImageViewer,
    Center,
    Right,
  };

  return (
    <MDXProvider components={shaderComponents}>
      <SEO seo={seo} />
      <Header />
      <main className={styles.main_container}>{children}</main>
      <Footer />
      <FollowUsModal />
    </MDXProvider>
  );
};

export default Layout;
