import * as React from "react"

import * as styles from "./Main.module.css"

type MainProps = {
  sidebar?: boolean
}

const Main: React.FC<MainProps> = ({ children, sidebar }) => {
  return <div className={`${styles.content}${sidebar ? ` ${styles.has_sidebar}` : ""}`}>{children}</div>
}

export default Main
