import { IArtist } from "models/Artist"
import _ from "lodash"

export const getNamesInLine = (artists: IArtist[], separate?: boolean) => {
  if (separate) {
    return artists
      .map((artist, index) => (index !== artists.length - 1 ? `${artist.name},` : `${artist.name}`))
      .toString()
      .replace(/,/g, "")
  }
  return artists
    .map((artist, index) => (index !== artists.length - 1 ? `${artist.name} x ` : `${artist.name}`))
    .toString()
    .replace(/,/g, "")
}

export const getNamesInLineFromKebabCase = (names: string[], separate?: boolean) => {
  if (separate) {
    return names
      .map((name, index) => (index !== names.length - 1 ? `${_.startCase(name)},` : `${_.startCase(name)}`))
      .toString()
      .replace(/,/g, "")
  }
  return names
    .map((name, index) => (index !== names.length - 1 ? `${_.startCase(name)} x ` : `${_.startCase(name)}`))
    .toString()
    .replace(/,/g, "")
}
