// @ts-nocheck
import React, { Component } from "react"
import _ from "lodash"
import { Index } from "elasticlunr"
import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getNamesInLineFromKebabCase } from "functions"

import * as styles from "./Search.module.css"

const Search = () => (
  <StaticQuery
    query={graphql`
      query SearchIndexQuery {
        siteSearchIndex {
          index
        }
      }
    `}
    render={data => <SearchBar searchIndex={data.siteSearchIndex.index} />}
  />
)

export default Search

class SearchBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      query: ``,
      results: {},
    }
  }

  render() {
    return (
      <>
        <div className={styles.search}>
          <input
            className={styles.search_input}
            type="text"
            placeholder="Buscar..."
            value={this.state.query}
            onChange={this.search}
          />
          <span className={styles.search_icon}>
            <FontAwesomeIcon icon={["fas", "search"]} />
          </span>
          {!_.isEmpty(this.state.results) && (
            <>
              <span className={styles.arrow} />
              <div className={styles.search_results_box}>
                <div className={styles.search_results_list}>
                  {this.state.results.artists && this.state.results.artists.length > 0 && (
                    <ul className={styles.search_results_list__section}>
                      <div className={styles.search_separator_title}>
                        <FontAwesomeIcon icon={["fas", "guitar"]} /> Artistas
                      </div>
                      {this.state.results.artists.map(page => (
                        <li className={styles.search_result_item} key={page.id}>
                          <Link
                            className={styles.search_result_item__link}
                            to={`/${page.collection}${page.slug}/songs`}
                          >
                            <img className={styles.search_result_item__image} src={page.image} alt={page.title} />
                            {page.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                  {this.state.results.users && this.state.results.users.length > 0 && (
                    <ul className={styles.search_results_list__section}>
                      <div className={styles.search_separator_title}>
                        <FontAwesomeIcon icon={["fas", "users"]} /> Usuarios
                      </div>
                      {this.state.results.users.map(page => (
                        <li className={styles.search_result_item} key={page.id}>
                          <Link
                            className={styles.search_result_item__link}
                            to={`/${page.collection}${page.slug}/articles`}
                          >
                            <img className={styles.search_result_item__image} src={page.image} />
                            {page.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                  {this.state.results.songs && this.state.results.songs.length > 0 && (
                    <ul className={styles.search_results_list__section}>
                      <div className={styles.search_separator_title}>
                        <FontAwesomeIcon icon={["fas", "music"]} /> Canciones
                      </div>
                      {this.state.results.songs.map(page => (
                        <li className={styles.search_result_item} key={page.id}>
                          <Link className={styles.search_result_item__link} to={`/${page.collection}${page.slug}`}>
                            {page.title} - {page.authors && getNamesInLineFromKebabCase(page.authors)}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                  {this.state.results.articles && this.state.results.articles.length > 0 && (
                    <ul className={styles.search_results_list__section}>
                      <div className={styles.search_separator_title}>
                        <FontAwesomeIcon icon={["fas", "book-open"]} /> Artículos
                      </div>
                      {this.state.results.articles.map(page => (
                        <li className={styles.search_result_item} key={page.id}>
                          <Link className={styles.search_result_item__link} to={`/${page.collection}${page.slug}`}>
                            {page.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                  {this.state.results.pages && this.state.results.pages.length > 0 && (
                    <ul className={styles.search_results_list__section}>
                      <div className={styles.search_separator_title}>
                        <FontAwesomeIcon icon={["fas", "link"]} /> Páginas
                      </div>
                      {this.state.results.pages.map(page => (
                        <li className={styles.search_result_item} key={page.id}>
                          <Link className={styles.search_result_item__link} to={`/${page.collection}${page.slug}`}>
                            {page.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </>
    )
  }
  getOrCreateIndex = () => (this.index ? this.index : Index.load(this.props.searchIndex))

  search = evt => {
    const query = evt.target.value
    this.index = this.getOrCreateIndex()
    let results = {}
    this.index
      .search(query)
      .map(({ ref }) => this.index.documentStore.getDoc(ref))
      .map(r => {
        results = { ...results, [r.collection]: results[r.collection] ? [...results[r.collection], r] : [r] }
      })
    this.setState({
      query,
      results,
    })
  }
}
