import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

// Model
import { ISite } from "models/Seo"
// Styles
import * as styles from "./Footer.module.css"

const Footer: React.FC = () => {
  const { site } = useStaticQuery(query) as ISite
  return (
    <footer className={styles.main_footer}>
      <div className={styles.main_footer_wrapper}>
        <p className={styles.sitename}>
          {site.siteMetadata.site_name} &copy; {new Date().getFullYear()}
        </p>
        <ul className={styles.main_footer__menu}>
          <li>
            <Link to={`/songs`}>Canciones</Link>
          </li>
          <li>
            <Link to={`/artists`}>Artistas</Link>
          </li>
          <li>
            <Link to={`/users`}>Usuarios</Link>
          </li>
          <li>
            <Link to={`/articles`}>Articulos</Link>
          </li>
        </ul>
        <ul className={styles.main_footer__menu}>
          <li>
            <Link to={`/pages/faq`}>FAQ</Link>
          </li>
          <li>
            <Link to={`/pages/contact`}>Contacto</Link>
          </li>
        </ul>
        <ul className={styles.main_footer__menu}>
          <li>
            <a rel="noopener noreferrer" target="_blank" href={`https://www.twitter.com/${site.siteMetadata.twitter}`}>
              Twitter
            </a>
          </li>
          <li>
            <a rel="noopener noreferrer" target="_blank" href={`https://fb.me/${site.siteMetadata.facebook}`}>
              Facebook
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={`https://www.instagram.com//${site.siteMetadata.instagram}`}
            >
              Instagram
            </a>
          </li>
          <li>
            <a rel="noopener noreferrer" target="_blank" href={`${site.siteMetadata.youtube}`}>
              Youtube
            </a>
          </li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer

const query = graphql`
  query {
    site {
      siteMetadata {
        site_name
        email
        twitter
        facebook
        instagram
        youtube
      }
    }
  }
`
