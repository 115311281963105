import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { createPortal } from "react-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Components
import Menu from "./Menu"
import Backdrop from "components/UIElements/Backdrop"
import Search from "components/UIElements/Search"
// Models
import { ISite } from "models/Seo"
// Styles
import * as styles from "./Header.module.css"

const Header: React.FC = () => {
  const [showMenu, setShowMenu] = React.useState<boolean>(false)
  const [showSearchBar, setShowSearchBar] = React.useState<boolean>(false)
  const { site } = useStaticQuery(query) as ISite

  const portalTo = typeof document !== `undefined` ? document.getElementById("menu-hook") : null
  const mobileMenu = (
    <nav className={`${styles.main_nav__mobile}${showMenu ? ` ${styles.show}` : ""}`}>
      <Menu />
    </nav>
  )

  const menuHandler = () => {
    setShowMenu(prevState => !prevState)
  }

  const closeMenu = () => {
    setShowMenu(false)
  }

  const openSearchBar = () => {
    setShowSearchBar(true)
  }

  const closeSearchBar = () => {
    setShowSearchBar(false)
  }

  return (
    <>
      <header className={styles.main_header}>
        {showSearchBar && (
          <section className={styles.search_bar}>
            <FontAwesomeIcon
              className={styles.close_search_bar}
              onClick={closeSearchBar}
              icon={["fas", "chevron-left"]}
            />
            <Search />
          </section>
        )}
        <div className={styles.main_header_wrapper}>
          <div onClick={menuHandler} className={styles.main_nav__mobile_button}>
            <FontAwesomeIcon icon={["fas", "bars"]} />
          </div>
          <Backdrop show={showMenu} showHeader onClose={closeMenu} />
          {portalTo && createPortal(mobileMenu, portalTo)}
          {!showSearchBar && (
            <Link to="/" className={styles.brand}>
              <h1 className={styles.brand__site_title}>{site.siteMetadata.site_name}</h1>
            </Link>
          )}
          <div className={styles.main_header__search}>
            <Search />
          </div>
          <div className={styles.main_header__search_mobile}>
            <FontAwesomeIcon
              onClick={openSearchBar}
              className={styles.main_header__search_mobile_icon}
              icon={["fas", "search"]}
            />
          </div>
          <nav className={styles.main_nav}>
            <Menu />
          </nav>
        </div>
      </header>
    </>
  )
}

export default Header

const query = graphql`
  query {
    site {
      siteMetadata {
        site_name
      }
    }
  }
`
