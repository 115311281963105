import * as React from "react"

import * as styles from "./ImageViewer.module.css"

type ImageViewerProps = {
  image: string
  translated_image: string
  alt_image?: string
  alt_translated_image?: string
}

const ImageViewer: React.FC<ImageViewerProps> = ({ image, translated_image, alt_image, alt_translated_image }) => {
  const [rangeValue, setRangeValue] = React.useState<number>(10)

  const rangeHandler = test => {
    setRangeValue(test.target.value)
  }

  return (
    <section>
      <div className={styles.images_slider}>
        <div style={{ width: `${rangeValue}%` }} className={styles.translated_image_container}>
          <img
            className={styles.translated_image}
            src={translated_image}
            alt={alt_translated_image || "Imagen traducida"}
          />
        </div>

        <div className={styles.original_image_container}>
          <img className={styles.original_image} src={image} alt={alt_image || "Imagen original"} />
        </div>
      </div>

      <div className={styles.range_container}>
        <input
          className={styles.range}
          type="range"
          defaultValue={rangeValue}
          min="1"
          max="100"
          onChange={rangeHandler}
          id="translated_image_width"
        />
      </div>
    </section>
  )
}

export default ImageViewer
