import { library } from "@fortawesome/fontawesome-svg-core"

/* FAB */
import {
  faTwitter,
  faInstagram,
  faLine,
  faFacebook,
  faSpotify,
  faYoutube,
  faDeezer,
  faAmazon,
  faGoogle,
  faGithub,
  faPinterest,
  faWhatsapp,
  faTelegram,
} from "@fortawesome/free-brands-svg-icons"

/* FAS */
import {
  faChevronLeft,
  faChevronRight,
  faGlobe,
  faStar,
  faInfoCircle,
  faCheckCircle,
  faBars,
  faCoffee,
  faSearch,
  faGuitar,
  faUsers,
  faMusic,
  faBookOpen,
  faLink,
  faTimes,
} from "@fortawesome/free-solid-svg-icons"
/* FAS */
import { faClock, faCalendarAlt } from "@fortawesome/free-regular-svg-icons"

library.add(
  faChevronLeft,
  faChevronRight,
  faGithub,
  faGoogle,
  faInstagram,
  faSpotify,
  faDeezer,
  faAmazon,
  faYoutube,
  faFacebook,
  faTwitter,
  faLine,
  faPinterest,
  faWhatsapp,
  faTelegram,
  faGlobe,
  faStar,
  faInfoCircle,
  faCheckCircle,
  faBars,
  faCoffee,
  faClock,
  faCalendarAlt,
  faSearch,
  faGuitar,
  faUsers,
  faMusic,
  faBookOpen,
  faLink,
  faTimes
)
