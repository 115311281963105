import * as React from "react"

// Components
import Lyric from "components/UIElements/Lyric"
// Styles
import * as styles from "./SongLyrics.module.css"

type SongLyricsProps = {
  children: React.ReactNodeArray
}

type Lang = {
  exist: boolean
  lyric: React.ReactNode
  isActive: boolean
  uniqueActive: boolean
}

type LyricState = {
  rom: Lang
  esp: Lang
  jap: Lang
}

const getInitialState = (children: any[]): LyricState => {
  const romaji = children?.filter(c => c.props.romaji)
  const esp = children?.filter(c => c.props.esp)
  const jap = children?.filter(c => c.props.jap)

  const hasRomaji = romaji.length > 0
  const hasEsp = esp.length > 0
  const hasJap = jap.length > 0

  return {
    esp: { lyric: hasEsp ? esp : null, exist: hasEsp, isActive: hasEsp, uniqueActive: hasEsp },
    rom: {
      lyric: hasRomaji ? romaji : null,
      exist: hasRomaji,
      isActive: hasRomaji,
      uniqueActive: !hasEsp && hasRomaji,
    },
    jap: {
      lyric: hasJap ? jap : null,
      exist: hasJap,
      isActive: !hasRomaji && hasJap,
      uniqueActive: !hasEsp && !hasRomaji && hasJap,
    },
  }
}

const SongLyrics: React.FC<SongLyricsProps> = ({ children }) => {
  const [lyrics, setLyrics] = React.useState<LyricState>(() => getInitialState(children))

  const uniqueActiveHandler = (lang: string) => {
    switch (lang) {
      case "esp":
        setLyrics(prevState => {
          const newState = { ...prevState }
          newState.esp.uniqueActive = true
          newState.rom.uniqueActive = false
          newState.jap.uniqueActive = false
          return newState
        })
        break
      case "rom":
        setLyrics(prevState => {
          const newState = { ...prevState }
          newState.esp.uniqueActive = false
          newState.rom.uniqueActive = true
          newState.jap.uniqueActive = false
          return newState
        })
        break
      case "jap":
        setLyrics(prevState => {
          const newState = { ...prevState }
          newState.esp.uniqueActive = false
          newState.rom.uniqueActive = false
          newState.jap.uniqueActive = true
          return newState
        })
        break
      default:
        break
    }
  }

  const isActiveHandler = (lang: string) => {
    switch (lang) {
      case "esp":
        setLyrics(prevState => {
          const newState = { ...prevState }
          newState.esp.isActive = true
          return newState
        })
        break
      case "rom":
        setLyrics(prevState => {
          const newState = { ...prevState }
          newState.rom.isActive = true
          newState.jap.isActive = false
          return newState
        })
        break
      case "jap":
        setLyrics(prevState => {
          const newState = { ...prevState }
          newState.rom.isActive = false
          newState.jap.isActive = true
          return newState
        })
        break
      default:
        break
    }
  }

  return (
    <>
      <h2 className="title">Letra</h2>
      <section className={styles.song_lyrics__mobile}>
        <nav className={styles.song_lyrics__nav}>
          {lyrics.esp.exist && (
            <span data-active={lyrics.esp.uniqueActive} onClick={() => uniqueActiveHandler("esp")}>
              Español
            </span>
          )}
          {lyrics.rom.exist && (
            <span data-active={lyrics.rom.uniqueActive} onClick={() => uniqueActiveHandler("rom")}>
              Romaji
            </span>
          )}
          {lyrics.jap.exist && (
            <span data-active={lyrics.jap.uniqueActive} onClick={() => uniqueActiveHandler("jap")}>
              Japonés
            </span>
          )}
        </nav>
        <main className={styles.song_lyrics}>
          {lyrics.esp.exist && lyrics.esp.uniqueActive && (
            <Lyric className={styles.song_lyric_container}>{lyrics.esp.lyric}</Lyric>
          )}
          {lyrics.rom.exist && lyrics.rom.uniqueActive && (
            <Lyric className={styles.song_lyric_container}>{lyrics.rom.lyric}</Lyric>
          )}
          {lyrics.jap.exist && lyrics.jap.uniqueActive && (
            <Lyric className={styles.song_lyric_container}>{lyrics.jap.lyric}</Lyric>
          )}
        </main>
      </section>

      {/*  */}

      <section className={styles.song_lyrics__desktop}>
        <nav className={styles.song_lyrics__nav}>
          <div className={styles.song_lyrics__nav_options}>
            {lyrics.esp.exist && (
              <span data-active={lyrics.esp.isActive} onClick={() => isActiveHandler("esp")}>
                Español
              </span>
            )}
          </div>
          <div className={styles.song_lyrics__nav_options}>
            {lyrics.rom.exist && (
              <span data-active={lyrics.rom.isActive} onClick={() => isActiveHandler("rom")}>
                Romaji
              </span>
            )}
            {lyrics.jap.exist && (
              <span data-active={lyrics.jap.isActive} onClick={() => isActiveHandler("jap")}>
                Japonés
              </span>
            )}
          </div>
        </nav>
        <main className={styles.song_lyrics}>
          {lyrics.esp.exist && lyrics.esp.isActive && (
            <Lyric className={styles.song_lyric_container}>{lyrics.esp.lyric}</Lyric>
          )}
          {lyrics.rom.exist && lyrics.rom.isActive && (
            <Lyric className={styles.song_lyric_container}>{lyrics.rom.lyric}</Lyric>
          )}
          {lyrics.jap.exist && lyrics.jap.isActive && (
            <Lyric className={styles.song_lyric_container}>{lyrics.jap.lyric}</Lyric>
          )}
        </main>
      </section>
    </>
  )
}

export default SongLyrics
