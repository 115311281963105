import * as React from "react"
import { createPortal } from "react-dom"

// Styles
import * as styles from "./Backdrop.module.css"

type BackdropProps = {
  onClose: () => void
  show: boolean
  showHeader?: boolean
}

const Backdrop: React.FC<BackdropProps> = ({ show, onClose, showHeader }) => {
  const portalTo = typeof document !== `undefined` ? document.getElementById("backdrop-hook") : null
  const keepHeader = showHeader ? { top: "var(--header)" } : {}

  const backdropElement = <div style={keepHeader} className={styles.backdrop} onClick={onClose}></div>

  if (portalTo && show) return createPortal(backdropElement, portalTo)
  return <></>
}

export default Backdrop
