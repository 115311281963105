import * as React from "react"
import axios from "axios"
import { useForm } from "react-hook-form"

import * as styles from "./ContactForm.module.css"

type Inputs = {
  name: string
  email: string
  message: string
}

interface IServerState {
  submitting: boolean
  status: null | { ok: boolean; msg: string }
}

const ContactForm: React.FC = () => {
  const { register, handleSubmit, errors, formState, reset } = useForm<Inputs>({ mode: "onChange" })
  const [serverState, setServerState] = React.useState<IServerState>({
    submitting: false,
    status: null,
  })

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    })
    if (ok) {
      reset()
    }
  }

  const onSubmit = (data, e) => {
    const formData = new FormData()

    for (let field in data) {
      formData.append(field, data[field])
    }

    setServerState({ submitting: true, status: null })
    axios({
      method: "post",
      url: "https://getform.io/f/65a9854a-9b1f-4560-9ce5-78c02ef83d46",
      data: formData,
    })
      .then(r => {
        e.target.reset()
        handleServerResponse(true, "Form Submitted", e)
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, e)
      })
  }

  return (
    <>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="name">
          Nombre
          <input type="text" name="name" ref={register({ required: { message: "Escribe tu nombre", value: true } })} />
        </label>
        {errors.name?.message}
        <label htmlFor="email">
          Correo
          <input
            type="email"
            name="email"
            ref={register({
              required: { message: "Escribe tu correo para poder responderte", value: true },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Escribe un correo válido",
              },
            })}
          />
        </label>
        {errors.email?.message}
        <label htmlFor="message">
          Mensaje
          <textarea
            name="message"
            rows={10}
            ref={register({ required: { message: "Escribe tu mensaje", value: true } })}
          />
        </label>
        {errors.message?.message}
        <button disabled={!formState.isValid || serverState.submitting} type="submit">
          Enviar
        </button>
        <section style={{ textAlign: "center" }}>
          {serverState.submitting && <span>Enviando...</span>}
          {serverState.status && serverState.status.ok && <span>¡Mensaje recibido!</span>}
        </section>
      </form>
    </>
  )
}

export default ContactForm
