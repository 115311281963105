// extracted by mini-css-extract-plugin
export const search = "Search-module--search--2SoLB";
export const search_input = "Search-module--search_input--35DOO";
export const search_icon = "Search-module--search_icon--2ZGQZ";
export const arrow = "Search-module--arrow--3zy9y";
export const search_results_box = "Search-module--search_results_box--2gcBx";
export const search_results_list = "Search-module--search_results_list--38ptQ";
export const search_separator_title = "Search-module--search_separator_title--1NY1I";
export const search_results_list__section = "Search-module--search_results_list__section--3akwL";
export const search_result_item = "Search-module--search_result_item--s2QAQ";
export const search_result_item__link = "Search-module--search_result_item__link--1bjLb";
export const search_result_item__image = "Search-module--search_result_item__image--2YtmY";