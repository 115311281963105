import * as React from "react"

type LyricProps = {
  esp?: boolean
  romaji?: boolean
  jap?: boolean
  className?: any
}

const getLang = (esp?: boolean, romaji?: boolean, jap?: boolean) => {
  if (esp) return "Español"
  if (romaji) return "Romaji"
  if (jap) return "Japonés"
}

const Lyric: React.FC<LyricProps> = ({ esp, romaji, jap, className, children }) => {
  const lang = getLang(esp, romaji, jap)
  return (
    <>
      <div className={className} lang-data={lang}>
        {children}
      </div>
    </>
  )
}

export default Lyric
